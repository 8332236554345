<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12"> <h1>create employee dispute group</h1></div>
                <div class="col-12"><router-link class="btn" to="/dispute/employee/group/show" active-class="active" style="float: right;">Back</router-link></div>
            </div>
        </div>
        <div class="container">
            <form class="row" @submit.prevent="onSubmit" style="margin-top: 40px;">
                <div class="col-4">
                    <div class="form-group">
                        <input v-model="invNum" type="text" class="form-control" placeholder="Enter order number">
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-primary">Create</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"

	export default {
		name: "DisputeEmployeeCreateGroup",
		data() {
			return {
				invNum: '',
			}
		},
		computed: {
			...mapGetters({
				activeLanguageName: 'getActiveLanguageName'
			})
		},
		mounted() {
		},
		methods: {
			onSubmit() {
				if (this.invNum.length) {
					disputeAxios
						.get(`/api/employee-dispute/group/check/${this.invNum}`)
						.then(({ data }) => {
							if (data.code === 2006) {
								let confirm = window.confirm(data.message + '\nWould you like to add additional dispute?')
                                confirm ? this.$router.push(`/dispute/employee/create/${data.response.id}`) : null
                            }
							else if (data.code === 2007) { this.createDisputeGroup() }
                        })
						.catch(err => console.error(err))
				} else {
					EventBus.$emit('showAlertModal', 'Please enter invoice number')
				}
			},
			createDisputeGroup() {
				let data = {
					invoice: this.invNum,
                    locale: this.activeLanguageName,
				}

				disputeAxios
					.post('/api/employee-dispute/group/create', data)
					.then(({ data }) => {
						this.$router.push(`/dispute/employee/create/${data.id}`)
                    })
					.catch(err => console.error(err))
			}
		},
	}
</script>

<style scoped>

</style>